import React from "react";
import { Link } from "gatsby";
import Image from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  SubTitle,
  Text,
  Wrapper,
  SecondWrapper,
  DotIcon,
  ListBox,
  StyledImg,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const ClearBraces = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
        <BreadcrumbItem Link="/what-we-do/clear-braces" Text="Clear Braces" />
      </Breadcrumb>
      <BannerImages imagesrc="clearBraces" />
      <PageContainer>
        <Container>
          <Title>Clear Braces</Title>
          <Wrapper>
            <ContentsContainer>
              <Text>
                In many cases, braces are the perfect choice as they are
                efficient, effective and the fastest option. However, you may
                have disregarded them off due to the aesthetic appearance...
              </Text>
              <Text>
                So, there is another option! Ceramic braces may be the perfect
                alternative option for you.
              </Text>
              <Text>
                Ceramic braces are traditional braces; however, they are made
                from a clear or tooth-coloured ceramic material instead of
                metal. You may want to consider these if you are self-conscious
                and worried about the aesthetics of wearing braces. Saying that,
                these braces are not completely invisible and still need a
                little bit of tender loving care (yep, some TLC!) to ensure they
                don’t stain.
              </Text>

              <SubTitle>
                What makes these ceramic braces different to metal braces?
              </SubTitle>

              <Text>
                Ceramic braces can help you achieve your dream smile as they are
                more discreet!
              </Text>
              <Text>
                Whether you are an adult or teenager, you can choose clear
                ceramic braces, instead of the easily visible stainless-steel
                braces. Ceramic braces are especially popular among
                self-conscious adults who are looking for subtle solution to
                fixing their dental and jaw issues. They are an awesome option
                for people who usually work in public communication roles,
                attending university or generally people who do not want to draw
                attention to their braces. Lastly, the use of porcelain braces
                may be perfect if you suffer from a metal or nickel allergy.
              </Text>
              <Text>
                However, there are some things to consider with this type of
                brace! The ceramic braces are slower than metal braces, but
                faster at correcting issues than any other discreet option
                available like clear aligners. The other downside is these
                braces are a little more expensive than metal braces, but less
                expensive than other clear options.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.clearBraces1} />
            </ImageContainer>
          </Wrapper>
          <SecondWrapper>
            <ImageContainer>
              <StyledImg fluid={image.clearBraces5} />
            </ImageContainer>
            <ContentsContainer>
              <SubTitle>
                So, what exactly are the benefits and disadvantages of these
                braces?
              </SubTitle>

              <Text>Benefits</Text>
              <ListBox>
                <DotIcon />
                <p>Less visible than traditional metal braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Less interference with x-rays or imaging tests</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Move teeth faster than aligners</p>
              </ListBox>
              <br />
              <Text>Disadvantages</Text>

              <ListBox>
                <DotIcon />
                <p>Tooth movement is a little slower than metal</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Elastic bands attaching wires to the brackets can stain</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>More expensive than metal braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>
                  Porcelain is less more abrasive than metal and cannot be used
                  in some situations
                </p>
              </ListBox>
              <br />
              <Text>
                Disclaimer – so yes... the elastic bands attached to the braces
                can be stained... but this can be minimised through regular
                brushing and flossing, avoiding foods and drinks which cause
                staining and avoiding whitening toothpastes. Lastly and
                importantly, it means you have to visit our team at Gippsland
                Orthodontics at regular times to keep things healthy and happy!
              </Text>
              <Text>
                If you would like to find out more about ceramic braces or the
                options available to you, please get in contact with Gippsland
                Orthodontics <Link to={`/contact`}>here</Link>
              </Text>
            </ContentsContainer>
          </SecondWrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default ClearBraces;
