import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ClearBraces from "../../components/service-items/clear-braces";
import { colors } from "../../styles/variables";

const ClearBracesPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Clear Braces" />
    <ClearBraces />
  </Layout>
);

export default ClearBracesPage;
