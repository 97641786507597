import { BsDot } from "react-icons/bs";
import Img from "gatsby-image";
import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const Container = styled.div`
  display: block;
  margin-bottom: 2rem;
  color: ${colors.secondary};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 2rem;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const SecondWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const ImageContainer = styled.div`
  border-radius: 0%;
  @media (min-width: ${breakpoints.md}) {
    padding: 2rem 6rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex-basis: 48%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const StyledImg = styled(Img)`
  border-radius: 24px;
`;
export const ContentsContainer = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    flex-basis: 48%;
    padding: 0;
  }
`;
export const Title = styled.h1`
  font-size: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
`;

export const SubTitle = styled.h1`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  margin: 1rem auto 0;
  width: 100%;
`;

export const Text = styled.p`
  padding-bottom: 1rem;
  line-height: 1.8rem;
  margin-bottom: 0;
  font-family: "Red Hat Display", sans-serif;
`;
export const ContentBottom = styled.div`
  font-weight: 500;
  padding-bottom: 4rem;
`;
export const BannerImageMD = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;
export const BannerImageSM = styled.div`
  display: block;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;
export const DotIcon = styled(BsDot)`
  font-size: 2.5rem;
  height: 1.65rem;
  width: 1.65rem;
`;
export const ListBox = styled.div`
  display: flex;
  flex-direction: row;
  p {
    line-height: 1.8rem;
    padding-bottom: 0rem;
    margin: 0;
    width: calc(100% - 1.65rem);
  }
`;

export const NumberedBox = styled.div`
  padding: 0 0 16px 8px;
  p {
    line-height: 1.8rem;
    padding-bottom: 0rem;
    margin: 0;
  }
  .text {
    padding-left: 16px;
  }
`;
