import { useStaticQuery, graphql } from "gatsby";

const Image = () => {
  const data = useStaticQuery(graphql`
    fragment serviceImage on File {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    query {
      braces7: file(relativePath: { eq: "dental-services/braces8.jpg" }) {
        ...serviceImage
      }
      clearBraces1: file(
        relativePath: { eq: "dental-services/clear-brace2.jpeg" }
      ) {
        ...serviceImage
      }
      clearBraces5: file(
        relativePath: { eq: "dental-services/clear-brace5.jpeg" }
      ) {
        ...serviceImage
      }
      clearAligners5: file(
        relativePath: { eq: "dental-services/clear-aligners-5.jpeg" }
      ) {
        ...serviceImage
      }
      smileMan: file(
        relativePath: { eq: "dental-services/smile-man-2-min.jpg" }
      ) {
        ...serviceImage
      }
      smileGirl2: file(
        relativePath: { eq: "dental-services/smile-girl-2-min.jpg" }
      ) {
        ...serviceImage
      }
      girl2: file(relativePath: { eq: "dental-services/girl-2.jpg" }) {
        ...serviceImage
      }
      girl4: file(relativePath: { eq: "dental-services/girl-4.jpg" }) {
        ...serviceImage
      }
      girl61: file(relativePath: { eq: "dental-services/girl-6-1-min.jpg" }) {
        ...serviceImage
      }
      boysGirlsSmiles: file(
        relativePath: { eq: "dental-services/boys-girls-smiles.jpg" }
      ) {
        ...serviceImage
      }
    }
  `);

  return {
    braces7: data.braces7.childImageSharp.fluid,
    clearBraces1: data.clearBraces1.childImageSharp.fluid,
    clearBraces5: data.clearBraces5.childImageSharp.fluid,
    clearAligners5: data.clearAligners5.childImageSharp.fluid,
    smileMan: data.smileMan.childImageSharp.fluid,
    smileGirl2: data.smileGirl2.childImageSharp.fluid,
    girl2: data.girl2.childImageSharp.fluid,
    girl4: data.girl4.childImageSharp.fluid,
    girl61: data.girl61.childImageSharp.fluid,
    boysGirlsSmiles: data.boysGirlsSmiles.childImageSharp.fluid,
  };
};

export default Image;
